export interface IAPILegacyOS {
    id?: string;
    company?: string;

    /** codigo * descricao * quantidade * valor_unitario * desconto * valor_bruto * valor_liquido */
    pecas?: string[];
    /** codigo * descricao * quantidade * valor_unitario * desconto * valor_bruto * valor_liquido */
    servicos?: string[];
    /** codigo * descricao * quantidade * valor_unitario * desconto * valor_bruto * valor_liquido */
    servicosTerceiros?: string[];

    numeroOS: string;
    dataAbertura: string;
    dataFechamento: string;

    /** nome * telefone * documento */
    cliente: string;
    placa: string;
    valorBrutoPecas: number;
    descontoPecas: number;
    valorLiquidoPecas: number;
    valorBrutoServico: number;
    desscontoServico: number;
    valorLiquidoServico: number;
    valorBrutoOS: number;
    decontoOS: number;
    valorLiquidoOS: number;
    observacao: string;
    KM: string;
}

interface ILegacyOS {
    numeroOS: string;
    dataAbertura: string;
    dataFechamento: string;
    cliente: {
        nome: string;
        telefone: string;
        documento?: string;
    };
    placa: string;
    pecas: {
        codigo: string
        descricao: string
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    servicos: {
        codigo: string;
        descricao: string;
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    servicosTerceiros: {
        codigo: string;
        descricao: string;
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    valorBrutoPecas: number;
    descontoPecas: number;
    valorLiquidoPecas: number;
    valorBrutoServico: number;
    descontoServico: number;
    valorLiquidoServico: number;
    valorBrutoOS: number;
    decontoOS: number;
    valorLiquidoOS: number;
    observacao: string;
    KM: string;
}


export class LegacyOS implements ILegacyOS {
    id: string;
    numeroOS: string;
    dataAbertura: string;
    dataFechamento: string;
    cliente: {
        nome: string;
        telefone: string;
        documento?: string;
    };
    placa: string;
    pecas: {
        codigo: string
        descricao: string
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    servicos: {
        codigo: string;
        descricao: string;
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    servicosTerceiros: {
        codigo: string;
        descricao: string;
        quantidade: number;
        valor_unitario: number;
        desconto: number;
        valor_bruto: number;
        valor_liquido: number;
    }[];
    valorBrutoPecas: number;
    descontoPecas: number;
    valorLiquidoPecas: number;
    valorBrutoServico: number;
    descontoServico: number;
    valorLiquidoServico: number;
    valorBrutoOS: number;
    decontoOS: number;
    valorLiquidoOS: number;
    observacao: string;
    KM: string;

    constructor(apiLegacyOS: IAPILegacyOS) {
        this.id = apiLegacyOS.id;
        this.numeroOS = apiLegacyOS.numeroOS;
        this.dataAbertura = apiLegacyOS.dataAbertura;
        this.dataFechamento = apiLegacyOS.dataFechamento;
        this.placa = apiLegacyOS.placa;;
        this.valorBrutoPecas = apiLegacyOS.valorBrutoPecas;
        this.descontoPecas = apiLegacyOS.descontoPecas;
        this.valorLiquidoPecas = apiLegacyOS.valorLiquidoPecas;
        this.valorBrutoServico = apiLegacyOS.valorBrutoServico;
        this.descontoServico = apiLegacyOS.desscontoServico;
        this.valorLiquidoServico = apiLegacyOS.valorLiquidoServico;
        this.valorBrutoOS = apiLegacyOS.valorBrutoOS;
        this.decontoOS = apiLegacyOS.decontoOS;
        this.valorLiquidoOS = apiLegacyOS.valorLiquidoOS;
        this.observacao = apiLegacyOS.observacao;
        this.KM = apiLegacyOS.KM;

        const [nome, telefone, document] = (apiLegacyOS.cliente || "").split('*');
        this.cliente = {
            nome: nome,
            telefone: telefone,
            documento: document
        }

        this.pecas = [];
        if (apiLegacyOS.pecas && Array.isArray(apiLegacyOS.pecas)) {
            for (const peca of apiLegacyOS.pecas) {
                let [codigo, descricao, quantidade, valor_unitario, desconto, valor_bruto, valor_liquido] = peca.split('*');
                this.pecas.push({
                    codigo: codigo.trim(),
                    descricao: descricao.trim(),
                    quantidade: Number(quantidade),
                    desconto: Number(desconto),
                    valor_bruto: Number(valor_bruto),
                    valor_liquido: Number(valor_liquido),
                    valor_unitario: Number(valor_unitario)
                })
            }
        }
        this.servicos = [];
        if (apiLegacyOS.servicos && Array.isArray(apiLegacyOS.servicos)) {
            for (const servico of apiLegacyOS.servicos) {
                let [codigo, descricao, quantidade, valor_unitario, desconto, valor_bruto, valor_liquido] = servico.split('*');
                this.servicos.push({
                    codigo: codigo.trim(),
                    descricao: descricao.trim(),
                    quantidade: Number(quantidade),
                    desconto: Number(desconto),
                    valor_bruto: Number(valor_bruto),
                    valor_liquido: Number(valor_liquido),
                    valor_unitario: Number(valor_unitario)
                })
            }
        }
        this.servicosTerceiros = [];
        if (apiLegacyOS.servicosTerceiros && Array.isArray(apiLegacyOS.servicosTerceiros)) {
            for (const terceiro of apiLegacyOS.servicosTerceiros) {
                let [codigo, descricao, quantidade, valor_unitario, desconto, valor_bruto, valor_liquido] = terceiro.split('*');
                this.servicosTerceiros.push({
                    codigo: codigo.trim(),
                    descricao: descricao.trim(),
                    quantidade: Number(quantidade),
                    desconto: Number(desconto),
                    valor_bruto: Number(valor_bruto),
                    valor_liquido: Number(valor_liquido),
                    valor_unitario: Number(valor_unitario)
                })
            }
        }
    }

    static sortingDataAccessor(os: LegacyOS, property: string) {
        switch (property) {
            case 'code': return os.numeroOS;
            case 'client': return os.cliente ? os.cliente.nome : '';
            case 'phone': return os.cliente ? os.cliente.telefone : '';
            case 'document': return os.cliente ? os.cliente.documento : '';
            case 'plate': return os.placa;
            case 'openedDate': return new Date(os.dataAbertura).valueOf();
            case 'closedDate': return new Date(os.dataFechamento).valueOf();
            case 'liquidValue': return os.valorLiquidoOS;
            default: return os[property]
        }
    }

}

